import { I18nNameSpaces } from 'common/services/i18n';
import { InvestingStrategy } from '../../../features/form/ducks';
import { VolatilityOptions } from '../HazardProfile';

type Fond = {
    name: string;
    ISIN: string;
    strategy: InvestingStrategy[];
    hazard: VolatilityOptions;
}

const fonds: Fond[] = [
    {
        name: `${I18nNameSpaces.Fonds}:moventum.LU2200142664`,
        ISIN: 'LU2200142664',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:moventum.LU2200141773`,
        ISIN: 'LU2200141773',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:moventum.LU2499924376`,
        ISIN: 'LU2499924376',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:moventum.LU2499924459`,
        ISIN: 'LU2499924459',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:moventum.LU2499924533`,
        ISIN: 'LU2499924533',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0128526901`,
        ISIN: 'LU0128526901',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
            InvestingStrategy.Conservative,
        ],
        hazard: VolatilityOptions.Lowest
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1022658667`,
        ISIN: 'LU1022658667',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
            InvestingStrategy.Conservative,
        ],
        hazard: VolatilityOptions.UpTo02Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0543330301`,
        ISIN: 'LU0543330301',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
            InvestingStrategy.Conservative,
        ],
        hazard: VolatilityOptions.UpTo02Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0012186622`,
        ISIN: 'LU0012186622',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
            InvestingStrategy.Conservative,
        ],
        hazard: VolatilityOptions.Lowest
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy0to100`,
        ISIN: 'Finax 0:100',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy10to90`,
        ISIN: 'Finax 10:90',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0093669546`,
        ISIN: 'LU0093669546',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1402199910`,
        ISIN: 'LU1402199910',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1147470683`,
        ISIN: 'LU1147470683',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0300737037`,
        ISIN: 'LU0300737037',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0252652382`,
        ISIN: 'LU0252652382',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0170473374`,
        ISIN: 'LU0170473374',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0592650674`,
        ISIN: 'LU0592650674',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0170474422`,
        ISIN: 'LU0170474422',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0496369546`,
        ISIN: 'LU0496369546',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1212701889`,
        ISIN: 'LU1212701889',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0229949994`,
        ISIN: 'LU0229949994',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU2155808657`,
        ISIN: 'LU2155808657',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU2155808491`,
        ISIN: 'LU2155808491',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0265291665`,
        ISIN: 'LU0265291665',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
            InvestingStrategy.Defensive,
        ],
        hazard: VolatilityOptions.UpTo05Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy20to80`,
        ISIN: 'Finax 20:80',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy30to70`,
        ISIN: 'Finax 30:70',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy40to60`,
        ISIN: 'Finax 40:60',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced
        ],
        hazard: VolatilityOptions.UpTo10Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy50to50`,
        ISIN: 'Finax 50:50',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced
        ],
        hazard: VolatilityOptions.UpTo10Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy60to40`,
        ISIN: 'Finax 60:40',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy70to30`,
        ISIN: 'Finax 70:30',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy80to20`,
        ISIN: 'Finax 80:20',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1402200197`,
        ISIN: 'LU1402200197',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1147470170`,
        ISIN: 'LU1147470170',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0962741061`,
        ISIN: 'LU0962741061',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1162221912`,
        ISIN: 'LU1162221912',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0768356593`,
        ISIN: 'LU0768356593',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1353032169`,
        ISIN: 'LU1353032169',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0170475312`,
        ISIN: 'LU0170475312',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0300742896`,
        ISIN: 'LU0300742896',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0294219869`,
        ISIN: 'LU0294219869',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0152980495`,
        ISIN: 'LU0152980495',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0211332563`,
        ISIN: 'LU0211332563',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0260870661`,
        ISIN: 'LU0260870661',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0294221097`,
        ISIN: 'LU0294221097',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1147471145`,
        ISIN: 'LU1147471145',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0131126574`,
        ISIN: 'LU0131126574',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0909060385`,
        ISIN: 'LU0909060385',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1402200353`,
        ISIN: 'LU1402200353',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1691812256`,
        ISIN: 'LU1691812256',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1093756242`,
        ISIN: 'LU1093756242',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0131126228`,
        ISIN: 'LU0131126228',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1093756168`,
        ISIN: 'LU1093756168',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0229951891`,
        ISIN: 'LU0229951891',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0768355603`,
        ISIN: 'LU0768355603',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0478345209`,
        ISIN: 'LU0478345209',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0300743944`,
        ISIN: 'LU0300743944',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0300740767`,
        ISIN: 'LU0300740767',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1956155946`,
        ISIN: 'LU1956155946',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823394779`,
        ISIN: 'LU0823394779',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823394852`,
        ISIN: 'LU0823394852',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1022403593`,
        ISIN: 'LU1022403593',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823417810`,
        ISIN: 'LU0823417810',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo10Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0608807433`,
        ISIN: 'LU0608807433',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0211326755`,
        ISIN: 'LU0211326755',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0608807516`,
        ISIN: 'LU0608807516',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0645132738`,
        ISIN: 'LU0645132738',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0122612848`,
        ISIN: 'LU0122612848',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0138075311`,
        ISIN: 'LU0138075311',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0316494805`,
        ISIN: 'LU0316494805',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0316494987`,
        ISIN: 'LU0316494987',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0889566211`,
        ISIN: 'LU0889566211',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0231790675`,
        ISIN: 'LU0231790675',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0352132285`,
        ISIN: 'LU0352132285',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0316494557`,
        ISIN: 'LU0316494557',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0366004207`,
        ISIN: 'LU0366004207',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0231790832`,
        ISIN: 'LU0231790832',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0316494391`,
        ISIN: 'LU0316494391',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0352132103`,
        ISIN: 'LU0352132103',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0260869739`,
        ISIN: 'LU0260869739',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0229940001`,
        ISIN: 'LU0229940001',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0390135415`,
        ISIN: 'LU0390135415',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0300743431`,
        ISIN: 'LU0300743431',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0390135332`,
        ISIN: 'LU0390135332',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0093666013`,
        ISIN: 'LU0093666013',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0496363770`,
        ISIN: 'LU0496363770',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0390137031`,
        ISIN: 'LU0390137031',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0300738514`,
        ISIN: 'LU0300738514',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0128520375`,
        ISIN: 'LU0128520375',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0195953822`,
        ISIN: 'LU0195953822',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0316492858`,
        ISIN: 'LU0316492858',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0390136736`,
        ISIN: 'LU0390136736',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0128525689`,
        ISIN: 'LU0128525689',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0114760746`,
        ISIN: 'LU0114760746',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0327757729`,
        ISIN: 'LU0327757729',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1165135440`,
        ISIN: 'LU1165135440',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1458425730`,
        ISIN: 'LU1458425730',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1620156130`,
        ISIN: 'LU1620156130',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823426308`,
        ISIN: 'LU0823426308',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823413587`,
        ISIN: 'LU0823413587',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0283511359`,
        ISIN: 'LU0283511359',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823394423`,
        ISIN: 'LU0823394423',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823416762`,
        ISIN: 'LU0823416762',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1956161167`,
        ISIN: 'LU1956161167',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823434583`,
        ISIN: 'LU0823434583',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823434310`,
        ISIN: 'LU0823434310',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823410997`,
        ISIN: 'LU0823410997',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1458428163`,
        ISIN: 'LU1458428163',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU1458427785`,
        ISIN: 'LU1458427785',
        strategy: [
            InvestingStrategy.Dynamic,
            InvestingStrategy.Balanced,
        ],
        hazard: VolatilityOptions.UpTo15Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy90to10`,
        ISIN: 'Finax 90:10',
        strategy: [
            InvestingStrategy.Dynamic
        ],
        hazard: VolatilityOptions.UpTo25Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:finax.strategy100to0`,
        ISIN: 'Finax 100:0',
        strategy: [
            InvestingStrategy.Dynamic
        ],
        hazard: VolatilityOptions.UpTo25Percent,
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU1803069274`,
        ISIN: 'LU1803069274',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0109394709`,
        ISIN: 'LU0109394709',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0523919115`,
        ISIN: 'LU0523919115',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0231205187`,
        ISIN: 'LU0231205187',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0260871552`,
        ISIN: 'LU0260871552',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0140363002`,
        ISIN: 'LU0140363002',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0229948087`,
        ISIN: 'LU0229948087',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0211333025`,
        ISIN: 'LU0211333025',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0294217905`,
        ISIN: 'LU0294217905',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0140362707`,
        ISIN: 'LU0140362707',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0231203729`,
        ISIN: 'LU0231203729',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU2125249990`,
        ISIN: 'LU2125249990',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU2063271972`,
        ISIN: 'LU2063271972',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0260870158`,
        ISIN: 'LU0260870158',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0768356080`,
        ISIN: 'LU0768356080',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0109981661`,
        ISIN: 'LU0109981661',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0390134954`,
        ISIN: 'LU0390134954',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0211331839`,
        ISIN: 'LU0211331839',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0316493583`,
        ISIN: 'LU0316493583',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0070302665`,
        ISIN: 'LU0070302665',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0109392836`,
        ISIN: 'LU0109392836',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0316493401`,
        ISIN: 'LU0316493401',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0229946628`,
        ISIN: 'LU0229946628',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0109391861`,
        ISIN: 'LU0109391861',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0078277505`,
        ISIN: 'LU0078277505',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0390134368`,
        ISIN: 'LU0390134368',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0768356247`,
        ISIN: 'LU0768356247',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0128522157`,
        ISIN: 'LU0128522157',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU2191006043`,
        ISIN: 'LU2191006043',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0626262082`,
        ISIN: 'LU0626262082',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0229945570`,
        ISIN: 'LU0229945570',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0052750758`,
        ISIN: 'LU0052750758',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0231793349`,
        ISIN: 'LU0231793349',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0128522744`,
        ISIN: 'LU0128522744',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU1863844665`,
        ISIN: 'LU1863844665',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0128526141`,
        ISIN: 'LU0128526141',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0128525929`,
        ISIN: 'LU0128525929',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0950372838`,
        ISIN: 'LU0950372838',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823411706`,
        ISIN: 'LU0823411706',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823421333`,
        ISIN: 'LU0823421333',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823421689`,
        ISIN: 'LU0823421689',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.UpTo25Percent
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0496367763`,
        ISIN: 'LU0496367763',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0496368142`,
        ISIN: 'LU0496368142',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0496367417`,
        ISIN: 'LU0496367417',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0300741732`,
        ISIN: 'LU0300741732',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:franklin.LU0300736062`,
        ISIN: 'LU0300736062',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0592650328`,
        ISIN: 'LU0592650328',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:templeton.LU0128526570`,
        ISIN: 'LU0128526570',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0265266980`,
        ISIN: 'LU0265266980',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0823414635`,
        ISIN: 'LU0823414635',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
    {
        name: `${I18nNameSpaces.Fonds}:bnpp.LU0265293521`,
        ISIN: 'LU0265293521',
        strategy: [
            InvestingStrategy.Dynamic,
        ],
        hazard: VolatilityOptions.Unlimited
    },
];

export const getAvailableFunds = ({ isFinaxAllowed }: { isFinaxAllowed: boolean }) => {
    if (isFinaxAllowed) {
        return fonds;
    }

    return fonds.filter(fund => {
        return !fund.ISIN.startsWith('Finax');
    })
}

export const fondHasStrategy = (name: string, strategy: InvestingStrategy) => {
    const split = name.split('-')
    const isin = split[split.length - 1]?.trim();

    if (!isin) {
        return true;
    }

    const fond = fonds.find(({ISIN}) => ISIN === isin);

    if (!fond) {
        return true;
    }

    return fond.strategy.includes(strategy);
}
