import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'antd';

import {
    RadioField,
    InputNumberField,
    TextareaField,
    SelectField,
    InputField,
} from '../../fields';
import {
    FondDataFormValues,
    HazardProfileFormValues,
} from '../../models/Forms';
import { Button } from '../../components';
import { localeConfig } from "../../config/locale.config";
import { InvestingStrategy } from '../../../features/form/ducks';

interface Props extends WithTranslation {
    onSubmit: (values: FondDataFormValues) => void;
    fondTypeOptions: { label: string; value: string }[];
    strategy?: { name: string; type: string };
    hazard?: HazardProfileFormValues | null;
    initialValues?: Partial<FondDataFormValues>;
    currency?: string;
}

function FondsForm({
    t,
    onSubmit,
    initialValues,
    strategy,
    fondTypeOptions,
    currency = '',
}: Props) {
    const schema = Yup.object<FondDataFormValues>().shape<FondDataFormValues>({
        code: Yup.string().required(t('form.required')),
        type: Yup.string().required(t('form.required')),
        oneTimeAmount: Yup.number().required(t('form.required')),
        regularAmount: Yup.number().required(t('form.required')),
        agio: Yup.number().required(t('form.required')),
        note: Yup.string(),
    });

    function handleOnSubmit(values: FondDataFormValues) {
        onSubmit && onSubmit(values);
    }

    function getFondOptions() {
        return localeConfig.funds
            .filter(
                fond =>
                    fond.strategy.includes(
                        strategy?.type as InvestingStrategy
                    )
            )
            .map(f => ({
                label: `${t(f.name)} - ${f.ISIN}`,
                value: `${t(f.name)} - ${f.ISIN}`,
            }));
    }

    function renderForm(formikBag: FormikProps<FondDataFormValues>) {
        return (
            <Form>
                <Row>
                    <Col>
                        {strategy ? (
                            <SelectField
                                showSearch
                                name="code"
                                options={getFondOptions()}
                                label={t('fondTable.columns.code')}
                            />
                        ) : (
                            <InputField
                                name="code"
                                label={t('fondTable.columns.code')}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RadioField
                            name="type"
                            type="horizontal"
                            options={fondTypeOptions}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputNumberField
                            name="regularAmount"
                            label={`${t(
                                'fondTable.columns.regularAmount'
                            )} ${currency}`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputNumberField
                            name="oneTimeAmount"
                            label={`${t(
                                'fondTable.columns.oneTimeAmount'
                            )} ${currency}`}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputNumberField
                            name="agio"
                            label={t('fondTable.columns.agio')}
                            max={100}
                            min={0}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextareaField
                            name="note"
                            label={t('fondTable.columns.note')}
                        />
                    </Col>
                </Row>
                <Button onClick={formikBag.submitForm} type="primary" block>
                    {t('fondTable.form.submit')}
                </Button>
            </Form>
        );
    }

    return (
        <Formik<FondDataFormValues>
            initialValues={{
                code: initialValues?.code ?? '',
                type: initialValues?.type ?? 'buy',
                oneTimeAmount: initialValues?.oneTimeAmount ?? 0,
                regularAmount: initialValues?.regularAmount ?? 0,
                agio: initialValues?.agio ?? 0,
                note: initialValues?.note ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}

export default withTranslation()(FondsForm);
