import { environment } from '../../environments/environment';
import { getAvailableFunds } from "../forms/FondForm/fonds";

const isSk = window.location.host.endsWith('.sk') || window.location.host.startsWith('localhost');

export const localeConfig = {
    host: isSk ? environment.host_sk : environment.host_cz,
    funds: getAvailableFunds({ isFinaxAllowed: isSk }),
} as const;

